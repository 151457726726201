import React from "react";
import Register from '../register';
import Modal from '../modal';
import MiniMenu from "../miniMenu";
import BottomMiniMenu from "../bottomMiniMenu";
import DownLoad from "../download";

const UnifiedContainer = (props) => {
    const { 
        addAffiliateParamToUrl, 
        mainSiteUrl, 
        nativeAppUrl, 
        tgData, 
        openModal, 
        closeModal, 
        isModal, 
        error_text, 
        toggleBottomMenu, 
        isBottomMenuOpen, 
        handleDimClick, 
        openCs, 
        changeTheme,
        pageName
    } = props;

    const getTaglineImage = (pageName) => {
        if (pageName === 'm3-001') {
            return `CXJBO-2011_Landingpage_tagline.png`;
        } else if (pageName === 'm3-004') {
            return `CXJ1-2193_Landingpage_tagline.png`;
        }
        return null;
    };

    const taglineImage = getTaglineImage(pageName);

    return (
        <div className={(changeTheme ? "containerV2" : "container") + ` ${pageName}-container`}>
            {isBottomMenuOpen && <div className="dimmed-overlay" onClick={handleDimClick}></div>}
            
            {(pageName === 'm3-001' || pageName === 'm3-004') && taglineImage && (
                <div className="mobile-only-tagline">
                    <img 
                        className="mobile-only-tagline-img" 
                        src={`/static/images/${pageName}/${taglineImage}`} 
                        alt="Mobile Tagline" 
                    />
                </div>
            )}

            <div className="register-qrcode web-register-qrcode">
                <div className="register-column">
                    {taglineImage && (
                        <img 
                            className='web-only-tagline'
                            src={`/static/images/${pageName}/${taglineImage}`} 
                            alt="Web Tagline"
                        />
                    )}
                    <Register 
                        openModal={openModal} 
                        mainSiteUrl={mainSiteUrl} 
                        changeTheme={changeTheme} 
                        addAffiliateParamToUrl={addAffiliateParamToUrl}
                        pageName={pageName}
                    />
                </div>
                <div className={`${pageName}-mobileDownload`}>
                    <DownLoad nativeAppUrl={nativeAppUrl}></DownLoad>
                </div>
            </div>

            {(pageName === 'm3-001' || pageName === 'm3-004') && (
                <>
                    <MiniMenu 
                        toggleBottomMenu={toggleBottomMenu} 
                        tgData={tgData} 
                        openCs={openCs} 
                    />
                    <BottomMiniMenu 
                        isVisible={isBottomMenuOpen} 
                        onClose={toggleBottomMenu} 
                        tgData={tgData} 
                        openCs={openCs} 
                    />
                </>
            )}

            <Modal 
                error_text={error_text} 
                closeModal={closeModal} 
                isModal={isModal}
            />
        </div>
    );
};

export default UnifiedContainer;
